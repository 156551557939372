.productDiv {
	padding: 74px 10px 20px 0px;
}

.productTitleFont {
	font-size: 2rem;
	font-weight: 600;
	letter-spacing: 2px;
	/* color: #b4b4b4; */
	text-transform: uppercase;
}

.productFont {
	font-size: 1.55rem;
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
}
