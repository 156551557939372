.productDiv {
	padding: 74px 10px 20px 0px;
}

.productFont {
	font-size: 1.55rem;
	font-weight: 600;
	letter-spacing: 2px;
}

.postFormatContent {
	position: relative;
}

.postThumbnail {
	max-width: 100%;
	height: auto;
	overflow: hidden;
}

.contentwrap {
	padding: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	top: 0;
	bottom: 0;
	display: table-cell;
	vertical-align: middle;
	overflow: hidden;
}

.entryTitle {
	display: table;
	font-size: 110%;
	height: 100%;
	text-transform: uppercase;
	width: 100%;
	margin: 0;
}

.featuredImage {
	display: table-cell;
	position: relative;
	transition: opacity 0.25s ease-in-out, background 0.25s ease-in-out;
	-moz-transition: opacity 0.25s ease-in-out, background 0.25s ease-in-out;
	-webkit-transition: opacity 0.25s ease-in-out, background 0.25s ease-in-out;
	vertical-align: middle;
	z-index: 1;
	color: #fff;
	text-decoration: none;
	opacity: 0;
	padding: 10%;
}

.featuredImage:hover {
	opacity: 0.9;
	color: #fff;
	background: rgba(115, 185, 231, 0.8);
}
