.ContacttDiv {
	padding: 74px 10px 20px 0px;
}

.ContactlFont {
	font-size: 1.55rem;
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.mapBd {
	display: block !important;
}
