.abortDiv {
	padding: 1vw 0px 1vw 0px;
	background-color: #f5f5f7;
}
.heading {
	font-size: 1.89rem;
	font-weight: 600;
	color: #b4b4b4;
	text-transform: uppercase;
	background: #ffffff;
	background-color: #f5f5f7;
	border-bottom: 0.28rem solid #0070c7;
	width: 11rem;
}
.headingContent {
	font-size: 2.1rem;
	font-weight: bold;
	line-height: 50px;
	margin-bottom: 2rem;
}
.contents {
	line-height: 2;
	font-size: 1.2rem;
}

.contentImg {
	position: absolute;
	top: 85%;
	left: 3%;
	color: rgba(255, 255, 255, 1);
}
.headingNum {
	display: inline-block;
	font-size: 5.3rem;
	font-weight: 700;
	margin-bottom: 10px;
	line-height: 55px;
	border-bottom: 16px solid #dedede;
	color: #000;
	margin-right: 15px;
}

.subheading {
	display: inline-block;
	font-size: 1rem;
	font-weight: "700";
	line-height: normal;
}

.subheading span {
	font-size: 3.5rem;
}

@media screen and (max-width: 700px) {
	.abortDiv {
		padding: 1vw 2vw 1vw 2vw;
		background-color: #f5f5f7;
	}
}
