.heading {
	font-size: 1.89rem;
	font-weight: 600;
	color: #b4b4b4;
	text-transform: uppercase;
	background: #ffffff;
	background-color: #f5f5f7;
	border-bottom: 0.28rem solid #0070c7;
	width: 14rem;
}
.headingContent {
	font-size: 2.1rem;
	font-weight: bold;
	line-height: 50px;
	margin-bottom: 2rem;
}
.contents {
	line-height: 2;
}
