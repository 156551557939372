.logo {
	float: left;
	width: 7rem;
	height: 29px;
	margin: 1rem 0rem 0rem 0;
	background-image: url(./../../common/image/logo.png);
	background-size: 100% 100%;
}
.site-layout-background {
	background: #fff;
}

.menuStyle {
	float: right;
	background-color: rgb(255, 255, 255, 0);
}

@media screen and (max-width: 800px) {
	.menuStyle {
		float: inherit !important;
	}
	.headerDiv {
		padding: 0 3vw !important;
	}
}
