.carouselDiv {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-height: 850px !important;
	line-height: 150px;
}
.carouselContent {
	position: relative;
	width: 120%;
	max-height: 750px !important;
}

@media screen and (max-width: 800px) {
	.carouselDiv {
		padding-top: 64px;
		position: relative;
		overflow: hidden;
		width: 100%;
		max-height: 550px !important;
		line-height: 0px;
	}
	.carouselContent {
		position: relative;
		width: 120%;
		max-height: 450px !important;
	}
}
.carouselImg {
	position: relative !important;
	width: 100%;
	height: 120%;
	-webkit-transform: matrix(1.5 0, 0, 2, 0, 0);
	-moz-transform: matrix(1.5 0, 0, 2, 0, 0);
	-o-transform: matrix(1.5 0, 0, 2, 0, 0);
	-webkit-transition-duration: 1s;
	-moz-transition-duration: 1s;
	-o-transition-duration: 1s;
}
.carouselImg:hover {
	-webkit-transform: scale(0.9, 0.9);
	-webkit-box-shadow: 0px 0px 30px #ccc;
	-moz-transform: scale(0.9, 0.9);
	-moz-box-shadow: 0px 0px 30px #ccc;
	-o-transform: scale(0.9, 0.9);
	-o-box-shadow: 0px 0px 30px #ccc;
}
.contentImg {
	position: absolute;
	top: 22%;
	left: 10%;
}
